import logo from "./logo.webp"
const clientData = {
    client_entity: 24,
    attorney_firm: 'Miller & Weisbrod',
    attorney_name: 'Josh Birmingham',
    attorney_number: '270-443-4431',
    attorney_email: 'jbirmingham@millerweisbrod.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_miller_weisbrod+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#14365B',
    siteLink: 'http://millerweisbrod.com',
    logo
}

export default clientData